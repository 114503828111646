<template>
  <div class="py-6 px-10 h-full" v-loading="loading">
    <div class="">
      <h1 class="text-2xl font-semibold text-gray-900">Конфигурация сайта</h1>
      <div class="flex flex-col mt-10">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8"
          >
            <div
              class="
                shadow
                overflow-hidden
                border-b border-gray-200
                sm:rounded-lg
              "
            >
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Заголовок сайта
                    </th>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Логотип
                    </th>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Главный текст
                    </th>
                    <th
                      scope="col"
                      class="
                        px-6
                        py-3
                        text-left text-xs
                        font-medium
                        text-gray-500
                        uppercase
                        tracking-wider
                      "
                    >
                      Язык
                    </th>
                    <th scope="col" class="relative px-6 py-3">
                      <span class="sr-only">Изменить</span>
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr v-for="person in people" :key="person.email">
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="flex items-center">
                        <div class="flex-shrink-0 h-10 w-10">
                          <img
                            class="h-10 w-10 rounded"
                            :src="person.icon"
                            alt=""
                          />
                        </div>
                        <div class="ml-4">
                          <div class="text-sm font-medium text-gray-900"></div>
                          <div class="text-sm text-gray-500">
                            {{ person.title }}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <div class="text-gray-900">
                        <img
                          class="h-10 w-20 rounded"
                          :src="person.logo"
                          alt=""
                        />
                      </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                      <span
                        class="
                          px-2
                          inline-flex
                          text-xs
                          leading-5
                          font-semibold
                          rounded-full
                          bg-green-100
                          text-green-800
                        "
                        v-if="smallText"
                      >
                        {{ person.maintext }}
                      </span>
                      <p
                        class="
                          px-2
                          inline-flex
                          text-xs
                          leading-5
                          font-semibold
                          rounded-full
                          bg-green-100
                          text-green-800
                          short-text
                        "
                        v-else
                      >
                        {{ person.maintext }}...
                      </p>
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {{ person.lang }}
                    </td>
                    <td
                      class="
                        px-6
                        py-4
                        whitespace-nowrap
                        text-right text-sm
                        font-medium
                      "
                    >
                      <router-link
                        :to="'/configurations/edit/' + person.id"
                        class="text-indigo-600 hover:text-indigo-900"
                        >Редактировать</router-link
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      people: "",
      smallText: this.length > 50,
      loading: true,
    };
  },
  computed: {
    cut() {
      if (this.people.maintext.length > 50) {
        return this.people.maintext.substring(0, 50) + "...";
      } else {
        return this.people.maintext;
      }
    },
  },
  mounted() {
    var config = {
      method: "get",
      url: `${process.env.VUE_APP_URL}api/config/`,
      headers: {
        Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    axios(config)
      .then((response) => {
        this.people = JSON.parse(JSON.stringify(response.data));
        console.log("people", JSON.stringify(this.people));
        this.loading = false;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>
