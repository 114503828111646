import axios from 'axios'
import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
  },
  mutations: {
    SET_USER_DATA(state, userdata) {
      state.user = userdata
      localStorage.setItem('user', JSON.stringify(userdata.auth_token))
      axios.defaults.headers.common['Authorization'] = `Token ${userdata.auth_token}`
      // console.log(userdata.auth_token)

    },
    CLEAR_USER_DATA(state) {
      state.user = null
      localStorage.removeItem('user')
      axios.defaults.headers.common['Authorization'] = null
    },
    SET_USER(state) {
      const user = localStorage.getItem('user')
      console.log('user', user);
      if (user) {
        state.user = JSON.parse(user)
        axios.defaults.headers.common['Authorization'] = `Token ${state.user.auth_token}`
      }

    },

  },
  actions: {
    async login({ commit }, credentials) {
      const { data } = await axios.post(`${process.env.VUE_APP_URL}auth/token/login/`, credentials)
      commit('SET_USER_DATA', data)
    },
    async getuid() {
      await axios.get(`${process.env.VUE_APP_URL}auth/users/me/`, {
        headers: {
          Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
        },
      }).then((usertoken) => {
        localStorage.setItem('SessId', JSON.parse(JSON.stringify(usertoken.data.id)))
      })

    },
    logout({ commit }) {
      commit("CLEAR_USER_DATA")
    },
  },
  modules: {
  },
  getters: {
    user: (state) => JSON.parse(JSON.stringify(state.user))
  }
})

