<template>
  <div class="px-10 py-6">
    <div class="flex">
      <h2 class="text-2xl sm:text-3xl font-semibold black-text">
        Галерея сайта
      </h2>
      <button
        type="button"
        class="
          ml-auto
          inline-flex
          items-center
          p-2
          border border-transparent
          rounded-full
          shadow-sm
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
        @click="$router.push('/gallery/options')"
      >
        <PlusIconOutline class="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
    <div class="mt-5">
      <ul
        role="list"
        class="
          grid grid-cols-2
          gap-x-4 gap-y-8
          sm:grid-cols-3
          sm:gap-x-6
          lg:grid-cols-4
          xl:gap-x-8
        "
      >
        <li v-for="file in files" :key="file.source" class="relative">
          <router-link :to="`/gallery/options/?edit=${file.id}`">
            <div
              class="
                group
                block
                w-full
                aspect-w-10 aspect-h-7
                rounded-lg
                bg-gray-100
                focus-within:ring-2
                focus-within:ring-offset-2
                focus-within:ring-offset-gray-100
                focus-within:ring-indigo-500
                overflow-hidden
              "
            >
              <img
                :src="file.attachment.url"
                alt=""
                style="height: 250px; width: 100%"
                class="object-cover group-hover:opacity-75"
              />
              <button type="button" class="absolute inset-0 focus:outline-none">
                <span class="sr-only">View details for {{ file.title }}</span>
              </button>
            </div>
            <p
              class="
                mt-2
                block
                text-sm
                font-medium
                text-gray-900
                truncate
                pointer-events-none
              "
            >
              {{ file.attachment.name.replace("gallery/", "") }}
            </p>
            <p
              class="
                block
                text-sm
                font-medium
                text-gray-500
                pointer-events-none
              "
            >
              {{ (file.attachment.size / (1024 * 1024)).toFixed(2) }} MB
            </p>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { PlusIcon as PlusIconOutline } from "@heroicons/vue/outline";

export default {
  components: {
    PlusIconOutline,
  },
  data() {
    return {
      files: [],
    };
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_URL}api/gallery/`, {
        headers: {
          Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
        },
      })
      .then((dt) => {
        this.files = JSON.parse(JSON.stringify(dt.data));
      });
  },
};
</script>