import { createRouter, createWebHistory } from 'vue-router'
// import store from '../store'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: Home,
    meta: {
      title: 'Панель управление',
      component: 'default'
    }
  },
  {
    path: '/configurations',
    name: 'Config',
    component: () => import(/* webpackChunkName: "Config" */ '../views/Config.vue'),
    meta: {
      title: 'Конфигурация сайта',
      component: 'default'
    }
  },
  {
    path: '/configurations/edit/:id',
    name: 'ConfigEditById',
    component: () => import(/* webpackChunkName: "Edit" */ '../views/ConfigEdit.vue'),
    meta: {
      title: 'Редактирование конфигурации',
      component: 'default'
    }
  },
  {
    path: '/siteelements/services=:id',
    name: 'Services',
    component: () => import(/* webpackChunkName: "element" */ '../views/SiteElementsServices.vue'),
    meta: {
      component: 'default',
      title: 'Приемущество сотрудничества'
    }
  },
  {
    path: '/siteelements/',
    name: 'SiteElements',
    component: () => import(/* webpackChunkName: "element" */ '../views/SiteElements.vue'),
    meta: {
      component: 'default',
      title: 'Изменение Элементов сайта'
    }
  },
  {
    path: '/siteelements/uz',
    name: 'SiteElementsUz',
    component: () => import(/* webpackChunkName: "element" */ '../views/uz/SiteElementsUz.vue'),
    meta: {
      component: 'default',
      title: 'UZ Изменение Элементов сайта'
    }
  },
  {
    path: '/siteelements/en',
    name: 'SiteElementsEn',
    component: () => import(/* webpackChunkName: "element" */ '../views/en/SiteElementsEn.vue'),
    meta: {
      component: 'default',
      title: 'EN Изменение Элементов сайта'
    }
  },
  {
    path: '/gallery',
    name: 'GalleryOptions',
    component: () => import(/* webpackChunkName: "element" */ '../views/gallery.vue'),
    meta: {
      component: 'default',
      title: 'Добавление / Редактирование Галереи сайта'
    }
  },
  {
    path: '/gallery/options',
    name: 'Gallery CRUD',
    component: () => import(/* webpackChunkName: "element" */ '../views/galleryoptions.vue'),
    meta: {
      component: 'default',
      title: 'Добавление / Удаление / Редактирование Галереи сайта'
    }
  },
  {
    path: '/production',
    name: 'Productions',
    component: () => import(/* webpackChunkName: "element" */ '../views/production.vue'),
    meta: {
      component: 'default',
      title: 'Добавление / Удаление / Редактирование Продукции сайта'
    }
  },
  {
    path: '/production/uz',
    name: 'ProductionsUz',
    component: () => import(/* webpackChunkName: "element" */ '../views/uz/production.vue'),
    meta: {
      component: 'default',
      title: 'Добавление / Удаление / Редактирование Продукции сайта UZ'
    }
  },
  {
    path: '/production/en',
    name: 'ProductionsEn',
    component: () => import(/* webpackChunkName: "element" */ '../views/en/production.vue'),
    meta: {
      component: 'default',
      title: 'Добавление / Удаление / Редактирование Продукции сайта EN'
    }
  },
  {
    path: '/production/edit=:id',
    name: 'ProdEdit',
    component: () => import(/* webpackChunkName: "element" */ '../views/editprod.vue'),
    meta: {
      component: 'default',
      title: 'Добавление / Удаление / Редактирование Продукции сайта EN'
    }
  },
  {
    path: '/infos',
    name: 'Informations',
    component: () => import(/* webpackChunkName: "element" */ '../views/informations.vue'),
    meta: {
      component: 'default',
      title: 'Редактирование Информации сайта'
    }
  },
  {
    path: '/infos/uz',
    name: 'InformationsUz',
    component: () => import(/* webpackChunkName: "element" */ '../views/uz/informations.vue'),
    meta: {
      component: 'default',
      title: 'Редактирование Информации сайта UZ'
    }
  },
  {
    path: '/infos/en',
    name: 'InformationsEn',
    component: () => import(/* webpackChunkName: "element" */ '../views/en/informations.vue'),
    meta: {
      component: 'default',
      title: 'Редактирование Информации сайта EN'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: 'Войдите в ПУ',
      component: 'empty'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: () => import(/* webpackChunkName: "404 not found" */ '../views/notfound.vue'),
    meta: {
      title: 'Страница не найдена 404',
      component: 'empty'
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Ecoborn'
  console.log('to', to);
  console.log('from', from);
  if (localStorage.getItem('user')) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (to.path === '/login') {
      next()
    } else {

      next('/login')
    }
  }
})
export default router