<template>
  <div class="py-6 px-10">
    <el-menu
      default-active="uz"
      class="el-menu-demo"
      mode="horizontal"
      :router="true"
      @select="handleSelect"
    >
      <el-menu-item index="uz" route="/production/uz">O'zbekcha</el-menu-item>
      <el-menu-item index="ru" route="/production">Русский</el-menu-item>
      <el-menu-item index="en" route="/production/en">English</el-menu-item>
    </el-menu>
    <div class="space-y-12">
      <div
        class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
      >
        <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl">
          Элементы продукции
        </h2>
        <p class="text-xl text-gray-500">Желательно писать описание коротко.</p>
      </div>

      <ul
        class="
          space-y-12
          sm:grid sm:grid-cols-2 sm:gap-x-4 sm:gap-y-10 sm:space-y-0
          lg:grid-cols-4 lg:gap-x-4
        "
        v-bind:class="{ 'h-80': people.length == 0 }"
      >
        <li v-for="person in people" :key="person.name">
          <div class="space-y-4">
            <div class="aspect-w-3 aspect-h-2">
              <img
                class="object-cover shadow-lg rounded-lg"
                style="width: 100%; height: 350px"
                :src="person.image"
                alt=""
              />
            </div>

            <div class="space-y-2">
              <div class="text-lg leading-6 font-semibold space-y-1">
                <h3>{{ person.title }}</h3>
                <p class="text-gray-500 sm:text-x" style="font-size: 12pt">
                  {{ person.description }}
                </p>
              </div>
            </div>
            <ul class="flex space-x-5">
              <li>
                <a
                  :href="`/production/edit=${person.id}`"
                  class="text-gray-400 hover:text-gray-500"
                >
                  <el-button icon="el-icon-edit" circle></el-button>
                </a>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  class="ml-2"
                  circle
                  @click="DeleteProduct(person.id)"
                ></el-button>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <el-popover
            placement="right"
            :width="300"
            trigger="hover"
            content="Добавить новый продукт"
          >
            <template #reference>
              <div
                class="
                  border-4 border-dashed border-gray-400
                  rounded-lg
                  flex
                  justify-center
                  items-center
                  w-full
                  h-full
                  text-gray-400
                  hover:border-indigo-500 hover:text-indigo-500
                "
                @click="open = true"
              >
                <PlusIconOutline class="h-10 w-10"></PlusIconOutline>
              </div>
            </template>
          </el-popover>
        </li>
      </ul>
    </div>
    <productCreate :open="open" :lang="lang" @closeDialog="open = false" />
  </div>
</template>

<script>
import { PlusIcon as PlusIconOutline } from "@heroicons/vue/outline";
import productCreate from "@/components/productCreate.vue";
import { ref } from "@vue/reactivity";

import axios from "axios";
// const people = [
//   {
//     title: "Asdas asd",
//     description: "slkdfjsfjdklsjflsjf;jk jdsjkfhdsjkfhj",
//     image:
//       "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
//   },
//   // More people...
// ];

export default {
  components: {
    PlusIconOutline,
    productCreate,
  },
  data() {
    return {
      people: [],
    };
  },
  setup() {
    const open = ref(false);
    const lang = ref("uz");
    return {
      open,
      lang,
    };
  },
  methods: {
    DeleteProduct(id) {
      axios
        .delete(`${process.env.VUE_APP_URL}api/production/${id}/?lang=uz`, {
          headers: {
            Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
          },
        })
        .then(() => {
          this.$message.success("Продукт удалён!");
          this.people = this.people.filter((p) => p.id !== id);
        });
    },
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_URL}api/production/?lang=uz`, {
        headers: {
          Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
        },
      })
      .then((recive) => {
        this.people = JSON.parse(JSON.stringify(recive.data));
      });
  },
};
</script>
