<template>
  <div class="px-10 py-6">
    <h2 class="text-2xl sm:text-3xl font-semibold" v-if="edit">
      Изменить Изображение
    </h2>
    <h2 class="text-2xl sm:text-3xl font-semibold" v-else>
      Добавить Изображение
    </h2>
    <el-upload
      class="mt-10"
      action="#"
      :auto-upload="false"
      :on-change="onChange"
      list-type="picture-card"
      :file-list="fileList"
    >
      <template #default>
        <i class="el-icon-plus" v-if="!Uploaded"></i>
        <i class="el-icon-edit" v-else></i>
      </template>
      <template #file="{ file }">
        <div style="width: 100%; height: 100%">
          <img
            class="el-upload-list__item-thumbnail h-full w-full"
            style="width: 100% !important; height: 100% !important"
            :src="file.url"
          />
          <span class="el-upload-list__item-actions">
            <span
              v-if="!disabled"
              class="el-upload-list__item-delete"
              @click="handleRemove(file)"
            >
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </template>
    </el-upload>
    <small class="text-gray-400"
      >Изображение должно быть меньше 3MB! Загрузите такое изображение.
      <br />Чтобы было комфортно смотреть на него с маленького и с большого
      дисплея</small
    >
    <br />
    <el-input v-model="nameField" placeholder="Пусто" class="mt-5 w-80" disabled
      ><template #prepend>Имя файла</template></el-input
    >
    <br />
    <el-input
      v-model="sizeField"
      placeholder="Пусто"
      class="mt-5"
      style="width: 200px; color: black !important"
      disabled
      ><template #prepend>Размер</template></el-input
    >
    <div class="flex mt-5">
      <button
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          border border-transparent
          text-sm
          font-medium
          rounded-md
          text-indigo-700
          bg-indigo-100
          hover:bg-indigo-200
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
        @click="send"
      >
        Отправить
      </button>
      <button
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          border border-gray-300
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-gray-700
          bg-white
          hover:bg-gray-50
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          ml-3
        "
        @click="$router.back()"
      >
        Отмена
      </button>
      <button
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          border border-transparent
          text-sm
          font-medium
          rounded-md
          text-white
          bg-red-500
          ml-3
          hover:bg-red-400
          focus:outline-none
          focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
        v-if="edit"
        @click="deleteImg"
      >
        Удалить
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref } from "@vue/reactivity";
export default {
  data() {
    return {
      edit: null,
      imageUrl: "",
      Uploaded: false,
      disabled: false,
      fileList: [],
    };
  },
  setup() {
    return {
      nameField: ref(""),
      sizeField: ref(null),
    };
  },
  methods: {
    deleteImg() {
      if (this.edit) {
        axios
          .delete(`${process.env.VUE_APP_URL}api/gallery/${this.edit}/`, {
            headers: {
              Authorization: `Token ${JSON.parse(
                localStorage.getItem("user")
              )}`,
            },
          })
          .then(() => {
            this.$router.push("/gallery");
          });
      }
    },
    handleRemove() {
      this.fileList = [];
      this.Uploaded = false;
      this.nameField = "Пусто";
      this.sizeField = "Пусто";
    },
    onChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error("Размер изображения не может превышать 3 MB!");
        this.handleRemove();
        return false;
      }
      this.Uploaded = true;
      this.nameField = file.name;
      this.sizeField = (file.size / (1024 * 1024)).toFixed(2) + "MB";
      this.imageUrl = file.raw;

      if (fileList.length > 1) {
        this.fileList = [file];
      }
    },
    send() {
      if (this.edit) {
        if (this.imageUrl) {
          var Data = new FormData();
          Data.append("img", this.imageUrl);
          axios
            .put(`${process.env.VUE_APP_URL}api/gallery/${this.edit}/`, Data, {
              headers: {
                Authorization: `Token ${JSON.parse(
                  localStorage.getItem("user")
                )}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              this.$message.success("Изображение успешно измененна");
              this.$router.back();
            });
        } else {
          this.$message.error(
            "Вы не изменили картинку. Картинка осталась преждняя"
          );
          this.$router.back();
        }
      } else {
        if (this.imageUrl) {
          var NewData = new FormData();
          NewData.append("img", this.imageUrl);
          axios
            .post(`${process.env.VUE_APP_URL}api/gallery/`, NewData, {
              headers: {
                Authorization: `Token ${JSON.parse(
                  localStorage.getItem("user")
                )}`,
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              this.$message.success("Изображение успешно добавлена!");
              this.$router.back();
            });
        } else {
          this.$message.error("Загрузите изображение!");
        }
      }
    },
  },
  mounted() {
    const { edit = "" } = this.$route.query;
    this.edit = edit;
    if (edit) {
      this.Uploaded = true;
      axios
        .get(`${process.env.VUE_APP_URL}api/gallery/${edit}/`, {
          headers: {
            Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
          },
        })
        .then((dt) => {
          this.fileList = [JSON.parse(JSON.stringify(dt.data.attachment))];
          this.nameField = JSON.parse(
            JSON.stringify(dt.data.attachment.name)
          ).replace("gallery/", "");
          this.sizeField =
            (
              JSON.parse(JSON.stringify(dt.data.attachment.size)) /
              (1024 * 1024)
            ).toFixed(2) + "MB";
        });
    }
  },
};
</script>
