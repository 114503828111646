<template>
  <div class="bg-white mx-3 my-2 px-5 py-6">
    <h1 class="text-2xl text-center font-semibold">Изменить элемент секции</h1>
    <div class="col-span-6 sm:col-span-3 mt-5">
      <input
        type="text"
        v-model="FormText"
        v-bind:class="{ 'border-red-400': FormError === 'Text' }"
        placeholder="Подзаголовок"
        class="
          mt-1
          focus:ring-indigo-500
          focus:border-indigo-500
          block
          w-full
          shadow-sm
          sm:text-sm
          border-gray-300
          rounded-md
        "
      />
    </div>
    <div class="text-center">
      <el-upload
        class="avatar-uploader mt-5 mx-a"
        :show-file-list="false"
        action="https://jsonplaceholder.typicode.com/posts/"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <small class="text-gray-400"
        >Иконка (Нажмите на иконку чтобы изменить его)</small
      >
    </div>
    <div class="sm:flex">
      <button
        class="
          flex
          items-center
          justify-center
          w-full
          px-5
          py-3
          border border-transparent
          text-base
          font-medium
          rounded-md
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          mt-4
          sm:mt-2
          sm:w-auto
          md:py-2
          md:text-lg
          md:px-4
        "
        @click="SaveChanges"
      >
        Изменить
      </button>
      <router-link to="/siteelements">
        <button
          to="/siteelements"
          class="
            w-full
            flex
            items-center
            justify-center
            px-5
            py-3
            border border-transparent
            text-base
            font-medium
            rounded-md
            text-indigo-700
            bg-indigo-100
            hover:bg-indigo-200
            mt-2
            sm:w-auto
            sm:ml-2
            md:py-2
            md:text-lg
            md:px-4
          "
        >
          Отмена
        </button>
      </router-link>
      <button
        class="
          flex
          items-center
          justify-center
          w-full
          px-5
          py-3
          ml-2
          border border-transparent
          text-base
          font-medium
          rounded-md
          text-white
          bg-red-600
          hover:bg-red-700
          mt-4
          sm:mt-2
          sm:w-auto
          md:py-2
          md:text-lg
          md:px-4
        "
        @click="Delete"
      >
        Удалить
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      FormText: "",
      FormError: "",
      imageUrl: "",
      position: "",
      index: null,
      FUrl: null,
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.FUrl = file.raw;
      console.log(this.FUrl);
    },
    Delete() {
      axios
        .delete(
          `${process.env.VUE_APP_URL}api/services/${this.$route.params.id}/`,
          {
            headers: {
              Authorization: `Token ${JSON.parse(
                localStorage.getItem("user")
              )}`,
            },
          }
        )
        .then(() => {
          this.$message.success("Удалено успешно!");
          this.$router.push("/siteelements");
        });
    },
    SaveChanges() {
      if (this.FormText == "") {
        this.$message.error("Вы не можете оставить поле Подзаголовок пустым!");
        this.FormError = "Text";
        return false;
      }
      var formData = new FormData();
      formData.append("text", this.FormText);
      if (this.FUrl) {
        formData.append("icon", this.FUrl);
      }
      formData.append("index", this.index);
      formData.append("position", this.position);

      axios
        .put(
          `${process.env.VUE_APP_URL}api/services/${this.$route.params.id}/`,
          formData,
          {
            headers: {
              Authorization: `Token ${JSON.parse(
                localStorage.getItem("user")
              )}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.$router.push("/siteelements");
          this.$message.success("Данные успешно измененны!");
        });
    },
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_URL}api/services/${this.$route.params.id}/`, {
        headers: {
          Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
        },
      })
      .then((response) => {
        this.FormText = JSON.parse(JSON.stringify(response.data.text));
        this.imageUrl = JSON.parse(JSON.stringify(response.data.icon));
        this.index = JSON.parse(JSON.stringify(response.data.index));
        this.position = JSON.parse(JSON.stringify(response.data.position));
        console.log(this.FormTitle);
      });
  },
};
</script>
