<template>
  <div class="px-10 mt-10 h-full" v-loading="loading">
    <el-menu
      default-active="en"
      class="el-menu-demo"
      mode="horizontal"
      :router="true"
      @select="handleSelect"
    >
      <el-menu-item index="uz" route="/siteelements/uz">O'zbekcha</el-menu-item>
      <el-menu-item index="ru" route="/siteelements">Русский</el-menu-item>
      <el-menu-item index="en" route="/siteelements/en">English</el-menu-item>
    </el-menu>
    <h1 class="text-2xl font-semibold text-gray-900 mb-5 mt-10">Наши услуги</h1>
    <div class="flex">
      <button
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          border border-transparent
          text-sm
          font-medium
          rounded-md
          text-white
          bg-black
          hover:bg-black
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black
        "
        @click="openmodal"
      >
        Добавить элемент
      </button>
      <button
        type="button"
        class="
          inline-flex
          items-center
          px-4
          py-2
          ml-2
          border border-transparent
          text-sm
          font-medium
          rounded-md
          text-indigo-700
          bg-indigo-100
          hover:bg-indigo-200
          focus:outline-none
          focus:ring-2
          focus:ring-offset-2
          focus:ring-indigo-500
        "
        @click="MainServiceAdd"
      >
        Сохранить
      </button>
    </div>
    <TransitionRoot as="template" :show="open">
      <Dialog
        as="div"
        class="fixed z-10 inset-0 overflow-y-auto"
        @close="open = false"
      >
        <div
          class="
            flex
            items-end
            justify-center
            min-h-screen
            pt-4
            px-4
            pb-20
            text-center
            sm:block sm:p-0
          "
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="
                inline-block
                align-bottom
                bg-white
                rounded-lg
                text-left
                overflow-hidden
                shadow-xl
                transform
                transition-all
                sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
              "
            >
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="pr-5">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <DialogTitle
                      as="h3"
                      class="text-lg leading-6 font-medium text-gray-900"
                    >
                      Добавить Элемент
                    </DialogTitle>
                    <div class="mt-2">
                      <el-input
                        type="text"
                        placeholder="Подзаголовок"
                        class="mt-5"
                        v-model="FormText"
                      ></el-input>
                      <el-upload
                        class="avatar-uploader mt-5"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                      >
                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
              >
                <button
                  type="button"
                  class="
                    w-full
                    inline-flex
                    justify-center
                    rounded-md
                    border border-transparent
                    shadow-sm
                    px-4
                    py-2
                    bg-green-500
                    text-base
                    font-medium
                    text-white
                    hover:bg-green-600
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-green-500
                    sm:ml-3 sm:w-auto sm:text-sm
                  "
                  @click="AddList"
                >
                  Добавить
                </button>
                <button
                  type="button"
                  class="
                    mt-3
                    w-full
                    inline-flex
                    justify-center
                    rounded-md
                    border border-gray-300
                    shadow-sm
                    px-4
                    py-2
                    bg-white
                    text-base
                    font-medium
                    text-gray-700
                    hover:bg-gray-50
                    focus:outline-none
                    focus:ring-2
                    focus:ring-offset-2
                    focus:ring-indigo-500
                    sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm
                  "
                  @click="open = false"
                  ref="cancelButtonRef"
                >
                  Отмена
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <el-row gutter="12" class="container">
      <el-col :span="12">
        <draggable
          class="list-group"
          :list="list1"
          group="people"
          @change="log"
          itemKey="name"
        >
          <template #item="{ element, index }">
            <el-card shadow="never" class="mt-10"
              >{{ index }}.
              <span class="font-semibold">{{ element.text }}</span>
              <router-link :to="'/siteelements/services=' + element.id">
                <el-button
                  icon="el-icon-edit"
                  class="float-right"
                  size="small"
                  circle
                ></el-button></router-link
            ></el-card>
          </template>
        </draggable>
      </el-col>

      <el-col :span="12">
        <draggable
          class="list-group"
          :list="list2"
          group="people"
          @change="log"
          itemKey="name"
        >
          <template #item="{ element, index }">
            <el-card class="mt-10" shadow="never">
              {{ index }}.
              <span class="font-semibold">{{ element.text }}</span>
              <router-link :to="'/siteelements/services=' + element.id">
                <el-button
                  icon="el-icon-edit"
                  class="float-right"
                  size="small"
                  circle
                ></el-button></router-link
            ></el-card>
          </template>
        </draggable>
      </el-col>

      <!-- 
    <rawDisplayer class="col-3" :value="list1" title="List 1" />

    <rawDisplayer class="col-3" :value="list2" title="List 2" /> -->
    </el-row>
    <hr class="mt-4" />
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import draggable from "vuedraggable";
export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  data() {
    return {
      list1: [],
      list2: [],
      list: [],
      servicesTag: "",
      FormText: "",
      imageUrl: "",
      FileUrl: null,
      loading: true,
    };
  },

  setup() {
    const open = ref(false);
    const openT = ref(false);
    return {
      open,
      openT,
    };
  },
  methods: {
    decoder(str) {
      var textArea = document.createElement("textarea");
      textArea.innerHTML = str;
      return textArea.value;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.FileUrl = file.raw;
    },
    AddList() {
      var Form = new FormData();
      Form.append("lang", "en");
      Form.append("text", this.FormText);
      Form.append("icon", this.FileUrl);
      Form.append("index", 0);
      Form.append("position", "left");

      axios
        .post(`${process.env.VUE_APP_URL}api/services/`, Form, {
          headers: {
            Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.open = false;
          this.$message.success("Успех!");
          this.updateData();
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.$message.error("Поля должны быть заполнены");
          }
          if (error.response.status === 500) {
            this.$message.error(
              "Ошибка с сервером! Пожалуйста попробуйте позже"
            );
          }
        });
    },
    openmodal() {
      this.open = true;
    },
    MainServiceAdd() {
      this.list1 = this.list1.map((l) => ({ ...l, position: "left" }));
      this.list2 = this.list2.map((l) => ({ ...l, position: "right" }));
      let list = this.list1.concat(this.list2);
      list = list.map((l, index) => ({ ...l, index }));

      Promise.all(
        list.map((lists) => {
          var FData = new FormData();
          FData.append("lang", "en");
          FData.append("text", lists.text);
          FData.append("index", lists.index);
          FData.append("position", lists.position);

          axios.put(
            `${process.env.VUE_APP_URL}api/services/${lists.id}/`,
            FData,
            {
              headers: {
                Authorization: `Token ${JSON.parse(
                  localStorage.getItem("user")
                )}`,
              },
            }
          );
        })
      )
        .then(() => {
          this.$message.success("Данные успешно измененны!");
        })
        .catch(() => {
          this.$message.error("Ошибка!");
        });
    },
    updateData() {
      axios
        .get(`${process.env.VUE_APP_URL}api/services/?lang=en`, {
          headers: {
            Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
          },
        })
        .then((reciveData) => {
          this.list1 = JSON.parse(JSON.stringify(reciveData.data)).filter(
            (item) => item.position === "left"
          );
          this.list2 = JSON.parse(JSON.stringify(reciveData.data)).filter(
            (item) => item.position === "right"
          );
        });
    },
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_URL}api/services/?lang=en`, {
        headers: {
          Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
        },
      })
      .then((reciveData) => {
        this.list1 = JSON.parse(JSON.stringify(reciveData.data)).filter(
          (item) => item.position === "left"
        );
        this.list2 = JSON.parse(JSON.stringify(reciveData.data)).filter(
          (item) => item.position === "right"
        );
        this.loading = false;
      });
  },
};
</script>
<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>