import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import VueNumber from 'vue-number-animation'
import Vuex from 'vuex'
import CKEditor from '@ckeditor/ckeditor5-vue';

import 'element-plus/lib/theme-chalk/index.css'
import './assets/tailwind.css'

import store from './store'

store.commit('SET_USER')
const app = createApp(App).use(store)


app.use(VueNumber)
app.use(ElementPlus)
app.use(Vuex)
app.use(CKEditor)
app.use(router).mount('#app')