<template>
  <div class="px-10 py-6">
    <el-menu default-active="uz" class="el-menu-demo" mode="horizontal" :router="true" @select="handleSelect">
      <el-menu-item index="uz" route="/infos/uz">O'zbekcha</el-menu-item>
      <el-menu-item index="ru" route="/infos">Русский</el-menu-item>
      <el-menu-item index="en" route="/infos/en">English</el-menu-item>
    </el-menu>
    <h2 class="text-3xl font-bold">О нас</h2>
    <br />
    <div class="container">
      <div class="mt-1">
        <ckeditor :editor="editor" v-model="aboutus" :config="editorConfig"></ckeditor>
      </div>
    </div>

    <h2 class="text-3xl font-bold mt-10">Полезное информация</h2>
    <br />
    <div class="container">
      <div class="mt-1">
        <ckeditor :editor="editor" v-model="information" :config="editorConfig"></ckeditor>
      </div>
    </div>
    <button type="button" class="
        inline-flex
        items-center
        mt-7
        px-4
        py-2
        border border-transparent
        text-sm
        font-medium
        rounded-full
        shadow-sm
        text-white
        bg-indigo-600
        hover:bg-indigo-700
        focus:outline-none
        focus:ring-2
        focus:ring-offset-2
        focus:ring-indigo-500
      " @click="save">
      Сохранить
    </button>
    <hr class="my-5" />
    <div class="grid grid-cols-1 sm:grid-cols-5">
      <div class="col-span-2 px-3">
        <div class="bg-white rounded pt-5">
          <h1 class="text-center text-3xl font-semibold">Добавить контакт</h1>
          <hr class="my-5" />
          <div class="px-5">
            <div class="
                relative
                border border-gray-300
                rounded-md
                px-3
                py-2
                shadow-sm
                focus-within:ring-1
                focus-within:ring-indigo-600
                focus-within:border-indigo-600
              ">
              <input type="text" v-model="prof" class="
                  block
                  w-full
                  border-0
                  p-0
                  text-gray-900
                  placeholder-gray-500
                  focus:ring-0
                  sm:text-sm
                " placeholder="Должность" />
            </div>
            <div class="
                mt-5
                relative
                border border-gray-300
                rounded-md
                px-3
                py-2
                shadow-sm
                focus-within:ring-1
                focus-within:ring-indigo-600
                focus-within:border-indigo-600
              ">
              <input type="text" v-model="ContactName" class="
                  block
                  w-full
                  border-0
                  p-0
                  text-gray-900
                  placeholder-gray-500
                  focus:ring-0
                  sm:text-sm
                " placeholder="Имя" />
            </div>
            <div class="
                mt-5
                relative
                border border-gray-300
                rounded-md
                px-3
                py-2
                shadow-sm
                focus-within:ring-1
                focus-within:ring-indigo-600
                focus-within:border-indigo-600
              ">
              <input type="text" v-model="ContactMail" class="
                  block
                  w-full
                  border-0
                  p-0
                  text-gray-900
                  placeholder-gray-500
                  focus:ring-0
                  sm:text-sm
                " placeholder="Почта" />
            </div>
            <div class="
                mt-5
                relative
                border border-gray-300
                rounded-md
                px-3
                py-2
                shadow-sm
                focus-within:ring-1
                focus-within:ring-indigo-600
                focus-within:border-indigo-600
              ">
              <input type="text" v-model="ContactPhone" class="
                  block
                  w-full
                  border-0
                  p-0
                  text-gray-900
                  placeholder-gray-500
                  focus:ring-0
                  sm:text-sm
                " placeholder="Телефон" />
            </div>
            <button type="button" class="
                inline-flex
                items-center
                justify-center
                px-4
                py-2
                mt-5
                mb-5
                w-full
                border border-transparent
                text-sm
                font-medium
                rounded-md
                shadow-sm
                text-white
                bg-indigo-600
                hover:bg-indigo-700
                focus:outline-none
                focus:ring-2
                focus:ring-offset-2
                focus:ring-indigo-500
              " @click="AddContact">
              Добавить контакт
            </button>
          </div>
        </div>
      </div>
      <div class="col-span-3">
        <div class="bg-white rounded">
          <div class="grid grid-cols-1 sm:grid-cols-2 px-10">
            <div class="left">
              <ul v-for="l in left" :key="l.id">
                <li class="mt-5">
                  <p class="text-gray-400">{{ l.prof }}</p>
                  <p class="text-gray-400">
                    <b>{{ l.name }}</b>
                  </p>
                  <p class="text-gray-400 flex">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="grey">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
                    </svg>
                    {{ l.phone }}
                  </p>
                  <p class="text-gray-400 inline-flex mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="grey">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                    </svg>
                    {{ l.email }}
                  </p>
                </li>
                <el-button type="danger" round @click="DeleteContact(l.id)"><i class="el-icon-delete"></i></el-button>
              </ul>
            </div>
            <div class="right">
              <ul v-for="r in right" :key="r.id">
                <li class="mt-5">
                  <p class="text-gray-400">{{ r.prof }}</p>
                  <p class="text-gray-400">
                    <b>{{ r.name }}</b>
                  </p>
                  <p class="text-gray-400 flex">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="grey">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z" />
                    </svg>
                    {{ r.phone }}
                  </p>
                  <p class="text-gray-400 inline-flex mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="grey">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                    </svg>
                    {{ r.email }}
                  </p>
                </li>
                <el-button type="danger" round @click="DeleteContact(r.id)"><i class="el-icon-delete"></i></el-button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  data() {
    return {
      aboutus: "",
      information: "",
      prof: "",
      ContactName: "",
      ContactMail: "",
      ContactPhone: "",
      left: [],
      right: [],
      editor: ClassicEditor,
      editorConfig: {},
    };
  },
  methods: {
    AddContact() {
      if (this.prof == "") {
        this.$message.error("Вы не заполнили поле Должность");
        return false;
      }
      if (this.ContactName == "") {
        this.$message.error("Вы не заполнили поле Имя");
        return false;
      }
      if (this.ContactMail == "") {
        this.$message.error("Вы не заполнили поле Почта");
        return false;
      }
      if (this.ContactPhone == "") {
        this.$message.error("Вы не заполнили поле Телефон");
        return false;
      }
      var Data = new FormData();
      Data.append("prof", this.prof);
      Data.append("name", this.ContactName);
      Data.append("email", this.ContactMail);
      Data.append("phone", this.ContactPhone);
      if (this.left.length > 3) {
        Data.append("position", "right");
      }
      axios
        .post(`${process.env.VUE_APP_URL}api/contacts/`, Data, {
          headers: {
            Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
          },
        })
        .then(() => {
          this.$message.success("Контактная информация добавлено!");
          this.Update();
        });
    },
    DeleteContact(id) {
      axios
        .delete(`${process.env.VUE_APP_URL}api/contacts/${id}/`, {
          headers: {
            Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
          },
        })
        .then(() => {
          this.$message.success("Контактная информация удалена!");
          this.Update();
        });
    },
    Update() {
      axios
        .get(`${process.env.VUE_APP_URL}api/contacts/`, {
          headers: {
            Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
          },
        })
        .then((response) => {
          const data = JSON.parse(JSON.stringify(response.data));
          console.log("data :>> ", data);
          this.left = JSON.parse(JSON.stringify(response.data)).filter(
            (item) => item.position === "left"
          );
          this.right = JSON.parse(JSON.stringify(response.data)).filter(
            (item) => item.position === "right"
          );
        });
    },
    save() {
      if (this.aboutus == "") {
        this.$message.error("Поля не могут быть пустыми!");
        return false;
      }
      if (this.information == "") {
        this.$message.error("Поля не могут быть пустыми!");
        return false;
      }
      var aboutdata = new FormData();
      aboutdata.append("info", this.aboutus);

      var infodata = new FormData();
      infodata.append("information", this.information);

      axios.put(`${process.env.VUE_APP_URL}api/about/3/`, aboutdata, {
        headers: {
          Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
        },
      }),
        axios
          .put(`${process.env.VUE_APP_URL}api/info/2/`, infodata, {
            headers: {
              Authorization: `Token ${JSON.parse(
                localStorage.getItem("user")
              )}`,
            },
          })
          .then(() => {
            this.$message.success("Изменения внесены!");
          });
    },
  },
  mounted() {
    axios
      .get(`${process.env.VUE_APP_URL}api/contacts/`, {
        headers: {
          Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
        },
      })
      .then((response) => {
        this.left = JSON.parse(JSON.stringify(response.data)).filter(
          (item) => item.position === "left"
        );
        this.right = JSON.parse(JSON.stringify(response.data)).filter(
          (item) => item.position === "right"
        );
      });
    axios
      .get(`${process.env.VUE_APP_URL}api/about/3/`, {
        headers: {
          Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
        },
      })
      .then((recive) => {
        this.aboutus = JSON.parse(JSON.stringify(recive.data.info));
      });

    axios
      .get(`${process.env.VUE_APP_URL}api/info/2/`, {
        headers: {
          Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
        },
      })
      .then((recive) => {
        this.information = JSON.parse(JSON.stringify(recive.data.information));
      });
  },
};
</script>





