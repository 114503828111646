<template>
  <div>
    <TransitionRoot style="z-index: 99999" as="template" :show="open">
      <Dialog
        as="div"
        static
        class="fixed inset-0 overflow-hidden"
        @close="$emit('closeDialog')"
        :open="open"
      >
        <div class="absolute inset-0 overflow-hidden">
          <DialogOverlay
            class="
              absolute
              inset-0
              bg-gray-500 bg-opacity-75
              transition-opacity
            "
          />

          <div class="fixed inset-y-0 pl-16 max-w-full right-0 flex">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <div class="w-screen max-w-md">
                <form
                  class="
                    h-full
                    divide-y divide-gray-200
                    flex flex-col
                    bg-white
                    shadow-xl
                  "
                >
                  <div class="flex-1 h-0 overflow-y-auto">
                    <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                      <div class="flex items-center justify-between">
                        <DialogTitle class="text-lg font-medium text-white">
                          Новый продукт
                        </DialogTitle>
                        <div class="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            class="
                              bg-indigo-700
                              rounded-md
                              text-indigo-200
                              hover:text-white
                              focus:outline-none focus:ring-2 focus:ring-white
                            "
                            @click="$emit('closeDialog')"
                          >
                            <span class="sr-only">Close panel</span>
                            <XIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      <div class="mt-1">
                        <p class="text-sm text-indigo-300">
                          Создайте новый продукт
                        </p>
                      </div>
                    </div>
                    <div class="flex-1 flex flex-col justify-between">
                      <div class="px-4 divide-y divide-gray-200 sm:px-6">
                        <div class="space-y-6 pt-6 pb-5">
                          <div>
                            <label
                              class="block text-sm font-medium text-gray-900"
                              >Изображение</label
                            >
                            <el-upload
                              action="#"
                              list-type="picture-card"
                              :auto-upload="false"
                              :file-list="fileList"
                              :on-change="handleOnChange"
                            >
                              <template #default>
                                <i class="el-icon-plus" v-if="!Uploaded"></i>
                                <i class="el-icon-edit" v-else></i>
                              </template>
                              <template #file="{ file }">
                                <div>
                                  <img
                                    class="el-upload-list__item-thumbnail"
                                    :src="file.url"
                                    alt=""
                                  />
                                  <span class="el-upload-list__item-actions">
                                    <span
                                      class="el-upload-list__item-preview"
                                      @click="handlePictureCardPreview(file)"
                                    >
                                      <i class="el-icon-zoom-in"></i>
                                    </span>
                                    <span
                                      v-if="!disabled"
                                      class="el-upload-list__item-delete"
                                      @click="handleRemove(file)"
                                    >
                                      <i class="el-icon-delete"></i>
                                    </span>
                                  </span>
                                </div>
                              </template>
                            </el-upload>
                            <el-dialog v-model="dialogVisible">
                              <img
                                style="width: 100%; height: auto"
                                :src="dialogImageUrl"
                                alt=""
                              />
                            </el-dialog>
                          </div>
                          <div>
                            <label
                              for="project-name"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Заголовок
                            </label>
                            <div class="mt-1">
                              <input
                                type="text"
                                v-model="title"
                                class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  border-gray-300
                                  rounded-md
                                "
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              for="description"
                              class="block text-sm font-medium text-gray-900"
                            >
                              Описание
                            </label>
                            <div class="mt-1">
                              <textarea
                                v-model="desc"
                                rows="4"
                                class="
                                  block
                                  w-full
                                  shadow-sm
                                  sm:text-sm
                                  focus:ring-indigo-500 focus:border-indigo-500
                                  border border-gray-300
                                  rounded-md
                                "
                              />
                              <small class="text-gray-400"
                                >Описание не поместиться если вы сделаете его
                                длинным. <br />Рекомендовано писать 40
                                символов!</small
                              >
                            </div>
                          </div>
                          <div></div>
                          <fieldset></fieldset>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                    <button
                      type="button"
                      class="
                        bg-white
                        py-2
                        px-4
                        border border-gray-300
                        rounded-md
                        shadow-sm
                        text-sm
                        font-medium
                        text-gray-700
                        hover:bg-gray-50
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                      @click="$emit('closeDialog')"
                    >
                      Отмена
                    </button>
                    <button
                      @click.prevent="send"
                      class="
                        ml-4
                        inline-flex
                        justify-center
                        py-2
                        px-4
                        border border-transparent
                        shadow-sm
                        text-sm
                        font-medium
                        rounded-md
                        text-white
                        bg-indigo-600
                        hover:bg-indigo-700
                        focus:outline-none
                        focus:ring-2
                        focus:ring-offset-2
                        focus:ring-indigo-500
                      "
                    >
                      Сохранить
                    </button>
                  </div>
                </form>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import axios from "axios";

export default {
  props: {
    open: Boolean,
    lang: String,
  },
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },

  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      fileList: [],
      Image: "",
      Uploaded: false,
      title: "",
      desc: "",
    };
  },
  methods: {
    handleRemove() {
      this.fileList = [];
      this.Uploaded = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleOnChange(file, fileList) {
      // console.log(this.FileList.length);
      this.Uploaded = true;
      this.Image = file.raw;
      if (fileList.length > 1) {
        this.fileList = [file];
      }
    },
    send() {
      if (this.title == "") {
        this.$message.error("Поле Заголовок пустой!");
        return false;
      }
      if (this.desc == "") {
        this.$message.error("Поле Описание пустой!");
      }

      var data = new FormData();
      data.append("lang", this.lang);
      data.append("image", this.Image);
      data.append("title", this.title);
      data.append("description", this.desc);
      axios
        .post(`${process.env.VUE_APP_URL}api/production/`, data, {
          headers: {
            Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$message.success("Продукт добавлен!");
          this.$emit("closeDialog");
        });
    },
  },
};
</script>