<template>
  <div class="py-6 h-full" v-loading="loading">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
      <h1 class="text-2xl font-semibold text-gray-900">Конфигурация сайта</h1>
      <br />
      <form>
        <el-input placeholder="Заголовок сайта" v-model="titleText"></el-input>
        <el-input
          placeholder="Главный текст"
          class="mt-7"
          v-model="mainText"
        ></el-input>
        <el-input
          placeholder="Второстепенный текст"
          v-model="subText"
          class="mt-7"
        ></el-input>
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePreview"
          :on-change="handleIconChange"
          :on-remove="handleRemove"
          :file-list="Icon"
          list-type="picture"
        >
          <el-button size="small" class="mt-7" type="primary"
            >Выберите иконку</el-button
          >
          <template #tip>
            <div class="el-upload__tip">
              jpg/png files with a size less than 500kb
            </div>
          </template>
        </el-upload>
        <el-upload
          class="upload-demo"
          action="https://jsonplaceholder.typicode.com/posts/"
          :on-preview="handlePreview2"
          :on-remove="handleRemove"
          :on-change="handleChange"
          :file-list="Logo"
          list-type="picture"
        >
          <el-button size="small" class="mt-7" type="primary"
            >Выберите Лого</el-button
          >
          <template #tip>
            <div class="el-upload__tip">
              jpg/png files with a size less than 500kb
            </div>
          </template>
        </el-upload>
        <br />
        <el-button type="primary" @click="Upload" plain>Отправить</el-button>

        <router-link to="/configurations" class="ml-3"
          ><el-button type="info">Отмена</el-button></router-link
        >
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      titleText: "",
      subText: "",
      mainText: "",
      Icon: [],
      Logo: [],
      id: null,
      lang: "",
      loading: true,
    };
  },
  methods: {
    Upload() {
      if (this.titleText == "" || this.subText == "" || this.mainText == "") {
        this.$message.error("Пожалуйста заполните все поля");
      }
      let ThisRoute = this.$route.params.id;
      let formData = new FormData();
      formData.append("lang", this.lang);
      formData.append("title", this.titleText);
      formData.append("maintext", this.mainText);
      formData.append("subtext", this.subText);
      if (this.Icon.length != 0) {
        formData.append("icon", this.Icon[0].raw);
      }
      if (this.Logo.length != 0) {
        formData.append("logo", this.Logo[0].raw);
      }

      axios
        .put(`${process.env.VUE_APP_URL}api/config/${ThisRoute}/`, formData, {
          headers: {
            Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$router.push({ name: "Config" });
          this.$message.success("Данные успешно измененны!");
          let ChangesData = new FormData();
          ChangesData.append("changed", "Конфигурация сайта");
          ChangesData.append(
            "text",
            `<b>Заголовок:</b> ${this.titleText}, <b>Главный Текст:</b> ${this.mainText}`
          );
          ChangesData.append("userid", localStorage.getItem("SessId"));
          if (this.Logo.length != 0) {
            ChangesData.append("image", this.Logo[0].raw);
          }
          ChangesData.append("changedId", this.id);
          axios.post(`${process.env.VUE_APP_URL}api/changes/`, ChangesData, {
            headers: {
              Authorization: `Token ${JSON.parse(
                localStorage.getItem("user")
              )}`,
              "Content-Type": "multipart/form-data",
            },
          });
        })
        .catch(() => {
          console.log("Ошибка с сервером. Пожалуйста попробуйте позже");
        });

      // .then(() => {
      //   this.$router.push({ name: "Config" });
      // });
      console.log(this.$route.params.id);
    },

    handleChange(file, fileList) {
      file;
      this.Logo = fileList.slice(-1);
    },
    handleIconChange(file, fileList) {
      file;
      this.Icon = fileList.slice(-1);
    },
  },
  mounted() {
    var config = {
      method: "get",
      url:
        `${process.env.VUE_APP_URL}api/config/` + this.$route.params.id + "/",
      headers: {
        Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    axios(config)
      .then((response) => {
        this.titleText = JSON.parse(JSON.stringify(response.data.title));
        this.mainText = JSON.parse(JSON.stringify(response.data.maintext));
        this.subText = JSON.parse(JSON.stringify(response.data.subtext));
        this.id = JSON.parse(JSON.stringify(response.data.id));
        this.lang = JSON.parse(JSON.stringify(response.data.lang));
        this.loading = false;
      })
      .catch(function (error) {
        console.log(error.data);
      });
  },
};
</script>
