<template>
  <component :is="component" />
</template>

<script>
import Default from "@/layouts/default.vue";
import Empty from "@/layouts/empty.vue";
export default {
  computed: {
    component() {
      const route = this.$route.meta.component || "default";
      return route === "default" ? Default : Empty;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>