<template>
  <div class="home">
    <div class="py-6 px-10">
      <div class="">
        <h1 class="text-2xl font-semibold text-gray-900">Панель Управление</h1>
      </div>
      <div class="">
        <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          <div
            class="
              relative
              bg-white
              pt-5
              px-4
              pb-12
              sm:pt-6
              sm:px-6
              shadow
              rounded-lg
              overflow-hidden
            "
          >
            <div>
              <div
                v-for="(item, index) in items"
                v-bind:key="index"
                class="absolute bg-red-500 rounded-md p-3"
              >
                <component
                  :is="item.icon"
                  class="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </div>
              <p class="ml-16 text-sm font-medium text-gray-500 truncate">
                Количество Заявок
              </p>
            </div>
            <div class="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p class="text-2xl font-semibold text-gray-900">
                <vue3-autocounter
                  ref="contactCounter"
                  :startAmount="0"
                  :endAmount="contactsnumber"
                  :duration="2"
                />
              </p>

              <div
                class="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6"
              >
                <div class="text-sm">
                  <a
                    href="/orders"
                    class="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Просмотреть все</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Replace with your content -->

      {{ currentRoute }}
      <!-- /End replace -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/vue/solid";
import { MailOpenIcon } from "@heroicons/vue/outline";
import axios from "axios";
import Vue3autocounter from "vue3-autocounter";
export default {
  name: "Home",
  components: {
    "vue3-autocounter": Vue3autocounter,
    ArrowSmDownIcon,
    ArrowSmUpIcon,
  },
  data() {
    return {
      contactsnumber: 0,
      items: [{ icon: MailOpenIcon }],
    };
  },
  methods: {},
  mounted() {
    var config = {
      method: "get",
      url: `${process.env.VUE_APP_URL}api/contacts/`,
      headers: {
        Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
      },
    };

    axios(config)
      .then((response) => {
        this.contactsnumber = response.data.count;
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>
