<template>
  <div class="bg-white mx-3 my-2 px-5 py-6">
    <h1 class="text-2xl text-center font-semibold">
      Изменить элемент продукции
    </h1>
    <div class="col-span-6 sm:col-span-3 mt-5">
      <input
        type="text"
        v-model="FormText"
        v-bind:class="{ 'border-red-400': FormError === 'Text' }"
        placeholder="Подзаголовок"
        class="
          mt-1
          focus:ring-indigo-500 focus:border-indigo-500
          block
          w-full
          shadow-sm
          sm:text-sm
          border-gray-300
          rounded-md
        "
      />
    </div>
    <div class="col-span-6 sm:col-span-3 mt-5">
      <input
        type="text"
        v-model="FormDesc"
        v-bind:class="{ 'border-red-400': FormError === 'Text' }"
        placeholder="Описание"
        class="
          mt-1
          focus:ring-indigo-500 focus:border-indigo-500
          block
          w-full
          shadow-sm
          sm:text-sm
          border-gray-300
          rounded-md
        "
      />
    </div>
    <div class="text-center">
      <el-upload
        class="mt-10"
        action="#"
        :auto-upload="false"
        :on-change="onChange"
        list-type="picture-card"
        :file-list="fileList"
      >
        <template #default>
          <i class="el-icon-plus" v-if="!Uploaded"></i>
          <i class="el-icon-edit" v-else></i>
        </template>
        <template #file="{ file }">
          <div style="width: 100%; height: 100%">
            <img
              class="el-upload-list__item-thumbnail h-full w-full"
              style="width: 100% !important; height: 100% !important"
              :src="file.url"
            />
            <span class="el-upload-list__item-actions">
              <span
                v-if="!disabled"
                class="el-upload-list__item-delete"
                @click="handleRemove(file)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </div>
        </template>
      </el-upload>
      <small class="text-gray-400"
        >Изображение должно быть меньше 3MB! Загрузите такое изображение.
        <br />Чтобы было комфортно смотреть на него с маленького и с большого
        дисплея</small
      >
    </div>
    <div class="sm:flex">
      <button
        class="
          flex
          items-center
          justify-center
          w-full
          px-5
          py-3
          border border-transparent
          text-base
          font-medium
          rounded-md
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          mt-4
          sm:mt-2 sm:w-auto
          md:py-2 md:text-lg md:px-4
        "
        @click="SaveChanges"
      >
        Изменить
      </button>
      <router-link to="/production">
        <button
          to="/production"
          class="
            w-full
            flex
            items-center
            justify-center
            px-5
            py-3
            border border-transparent
            text-base
            font-medium
            rounded-md
            text-indigo-700
            bg-indigo-100
            hover:bg-indigo-200
            mt-2
            sm:w-auto sm:ml-2
            md:py-2 md:text-lg md:px-4
          "
        >
          Отмена
        </button>
      </router-link>
      <button
        class="
          flex
          items-center
          justify-center
          w-full
          px-5
          py-3
          ml-2
          border border-transparent
          text-base
          font-medium
          rounded-md
          text-white
          bg-red-600
          hover:bg-red-700
          mt-4
          sm:mt-2 sm:w-auto
          md:py-2 md:text-lg md:px-4
        "
        @click="Delete"
      >
        Удалить
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      FormText: "",
      FormDesc: "",
      FormError: "",
      position: "",
      index: null,
      FUrl: null,
      edit: null,
      imageUrl: "",
      Uploaded: false,
      disabled: false,
      fileList: [],
      lang: "",
    };
  },
  methods: {
    deleteProduct() {
      if (this.edit) {
        axios
          .delete(`${process.env.VUE_APP_URL}api/production/${this.edit}/`, {
            headers: {
              Authorization: `Token ${JSON.parse(
                localStorage.getItem("user")
              )}`,
            },
          })
          .then(() => {
            this.$router.push("/production");
          });
      }
    },
    handleRemove() {
      this.fileList = [];
      this.Uploaded = false;
      this.nameField = "Пусто";
      this.sizeField = "Пусто";
    },
    onChange(file, fileList) {
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error("Размер изображения не может превышать 3 MB!");
        this.handleRemove();
        return false;
      }
      this.Uploaded = true;
      this.nameField = file.name;
      this.sizeField = (file.size / (1024 * 1024)).toFixed(2) + "MB";
      this.imageUrl = file.raw;

      if (fileList.length > 1) {
        this.fileList = [file];
      }
    },
    Delete() {
      axios
        .delete(
          `${process.env.VUE_APP_URL}api/production/${this.$route.params.id}/`,
          {
            headers: {
              Authorization: `Token ${JSON.parse(
                localStorage.getItem("user")
              )}`,
            },
          }
        )
        .then(() => {
          this.$message.success("Удалено успешно!");
          this.$router.push("/production");
        });
    },
    SaveChanges() {
      if (this.FormText == "") {
        this.$message.error("Вы не можете оставить поле Подзаголовок пустым!");
        this.FormError = "Text";
        return false;
      }
      if (this.FormDesc == "") {
        this.$message.error("Вы не можете оставить поле Описании пустым!");
        this.FormError = "Text";
        return false;
      }
      var formData = new FormData();
      formData.append("lang", this.lang);
      formData.append("title", this.FormText);
      formData.append("description", this.FormDesc);
      if (this.imageUrl) {
        formData.append("image", this.imageUrl);
      }

      axios
        .put(
          `${process.env.VUE_APP_URL}api/production/${this.$route.params.id}/`,
          formData,
          {
            headers: {
              Authorization: `Token ${JSON.parse(
                localStorage.getItem("user")
              )}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          this.$router.push("/production");
          this.$message.success("Данные успешно измененны!");
        });
    },
  },
  mounted() {
    axios
      .get(
        `${process.env.VUE_APP_URL}api/production/${this.$route.params.id}/`,
        {
          headers: {
            Authorization: `Token ${JSON.parse(localStorage.getItem("user"))}`,
          },
        }
      )
      .then((response) => {
        this.FormText = JSON.parse(JSON.stringify(response.data.title));
        this.fileList = [
          { url: JSON.parse(JSON.stringify(response.data.image)) },
        ];
        this.Uploaded = true;
        this.FormDesc = JSON.parse(JSON.stringify(response.data.description));
        this.lang = JSON.parse(JSON.stringify(response.data.lang));
        console.log(this.FormTitle);
      });
  },
};
</script>
